export const da = {
  // Views folder
  view: {
    homePage: {
      titles: {
        1: 'Ejendomstorvets medlem site',
        2: 'Ejendomstorvets light annoncør site',
        3: 'Ejendomstorvets premium annoncør site',
        4: 'Ejendomstorvets statistik site',
        5: 'Ejendomstorvets kommmune site',
        user: 'Ejendomstorvets bruger site',
      },
    },

    favoritesPage: {
      title: 'Favoritter',
      body: 'Her kan du se dine emner som du har markeret som favoritter',
    },

    searchAgentsPage: {
      title: 'Søge agenter',
      body: 'Her kan du se dine søge agenter',
      noAgents: 'Du har pt ingen agenter... Go make some!!!',
      refresh: 'Genindlæs listen',
    },

    accountShow: {
      title: ' @:common.common.account.term',
      publicData: 'Offentlige data vist på Ejendomstorvet',
      privateData: 'Debug data til Ejendomstorvet',
      accountUsersTitle: '@:common.common.account.term brugere',
      usersAssociated: 'Brugere som er tilknyttet denne @.lower:common.common.account.term',
      userIsAdmin: 'Og da du er admin, så må du gerne slette en brugers relation eller redigere brugerens rolle',
      addUser: 'Tilføj ny bruger til denne @.lower:common.common.account.term',
      labels: {
        id: '@:common.common.id',
        type: '@:common.common.type',
        name: '@:common.common.name',
        email: '@:common.common.email offentlig',
        ccEmail: 'Sekundær @.lower:common.common.email kontakt',
        website: '@:common.common.website',
        phoneNumber: '@:common.common.phoneNumber',
        description: '@:common.common.description',
        address: '@:common.common.address.term',
        accountType: '@:common.common.account.type',
        organization: '@:common.common.organization.term',
        user: '@:common.common.user.term',
        listingSupplier: '@:common.common.listingSupplier.term',
        billingId: '@:common.common.billingId.long',
      },
    },

    accountForm: {
      title: {
        create: 'Opret annoncør @.lower:common.common.account.term',
        update: 'Opdater annoncør @.lower:common.common.account.term',
      },
      publicData: 'Offentlige data vist på Ejendomstorvet',
      privateData: 'Ejendomstorvet',
      labels: {
        name: '@:common.common.name',
        email: 'Offentlig @:common.common.email',
        ccEmail: 'Sekundær @.lower:common.common.email kontakt',
        phoneNumber: '@:common.common.phoneNumber',
        website: '@:common.common.website',
        description: '@:common.common.description',
        address: '@:common.common.address.term',
        addressPlaceholder: 'Indtast adresse her',
        streetName: '@:common.common.address.streetName',
        streetNumber: '@:common.common.address.streetNumber',
        floor: '@:common.common.address.floor',
        door: '@:common.common.address.floorSide',
        zipCode: '@:common.common.address.zipCode',
        zipCodeName: '@:common.common.address.city',
        logourl: 'Logo',
      },
      errors: {
        phoneNumber: '@:common.common.phoneNumber',
        phoneNumberPrefix: 'Nummeret skal være præ-fikseret med lande kode (+45 eks.)',
        name: '@:common.common.name',
        email: '@:common.common.email',
        ccEmail: '@:common.common.email kontakter',
        website: '@:common.common.website',
        description: '@:common.common.description',
        address: '@:common.common.address.term',
        addressPlaceholder: 'Indtast adresse her',
        streetName: '@:common.common.address.streetName',
        streetNumber: '@:common.common.address.streetNumber',
        floor: '@:common.common.address.floor',
        door: '@:common.common.address.floorSide',
        zipCode: '@:common.common.address.zipCode',
        zipCodeName: '@:common.common.address.city',
        logourl: 'Logo',
      },
    },

    userProfileIndex: {
      title: 'Profil',
      fetchData: 'Henter data',
      userInfo: 'Bruger info fra Kinde',
      activeUserRoles: 'Aktive userroles',
      selectedAccount: {
        title: 'Aktiv @.lower:common.common.account.term',
        text: 'For at skifte @.lower:common.common.account.term',
        link: 'klik her',
      },
      changePassword: {
        title: 'Skift kodeord',
      },
    },
    selectAccountIndex: {
      title: 'Vælg en @.lower:common.common.account.term',
      mainMessage: 'Denne side vises fordi du har forøgt at tilgå en url og har mere end én @.lower:common.common.account.term at vælge imellem.',
      message: 'For at kunne benytte denne side skal du starte med at vælge en @.lower:common.common.account.term.',
      messageAdmin: 'Og fordi du er et-admin, så skal du også vælge user_roles',
      selectedAccount: 'Valgt @.lower:common.common.account.term',
    },
    accountStatistics: {
      title: 'Annoncestatistik',
      message: '',
    },
    marketStatistics: {
      title: 'Udbuds og omsætningsstatistik',
      message: 'Markedsdata fra ejendomstorvets database over annoncer på Ejendomstorvet',
    },
    noAccess: {
      default: {
        title: 'Ingen adgang',
        message: 'Ej, du har ikke lov til at være her.... go away. Det skyldes at du enten ikke er tilknyttet en @.lower:common.common.account.term eller er et-admin',
      },
      userRole: {
        title: 'Ingen adgang',
        message: 'Din bruger/konto har ikke den korrekte user_role til at opholde sig her. Adgang forbudt for dig!',
      },
      permission: {
        title: 'Ingen adgang',
        message: 'Din @.lower:common.common.account.term har ikke den korrekte permission til at opholde sig her. Adgang forbudt for dig!',
      },
      types: {
        title: 'Ingen adgang',
        message: 'Der er kun adgang konti med typen "broker", og det er denne konti ikke. Adgang forbudt for dig!',
      },
    },
    notFound: {
      title: '404',
      message: 'Siden du leder efter findes ikke',
    },
    listingIndex: {
      title: '@:common.common.listing.term',
      addListing: 'Opret @.lower:common.common.listing.term',
      labels: {
        account: '@:common.common.account.term',
        selectAccount: 'Vælg en @.lower:common.common.account.term',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        address: '@:common.common.address.term',
        status: '@:common.common.status',
        selectStatus: 'Vælg @.lower:common.common.status',
        selectListingType: 'Vælg @.lower:common.common.listingType.term',
      },
    },
    listingsCreate: {
      title: 'Opret annonce',
      labels: {
        rentalListing: '@.lower:common.common.rentalListing.term',
        investmentListing: '@.lower:common.common.investmentListing.term',
        salesListing: '@.lower:common.common.salesListing.term',
        officespaceListing: '@.lower:common.common.officespaceListing.term',
      },
    },

    investmentListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.investmentListing.term',
      titleUpdate: 'Opdater @.lower:common.common.investmentListing.term',
    },
    rentalListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.rentalListing.term',
      titleUpdate: 'Opdater @.lower:common.common.rentalListing.term',
    },
    salesListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.salesListing.term',
      titleUpdate: 'Opdater @.lower:common.common.salesListing.term',
    },
    officespaceListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.officespaceListing.term',
      titleUpdate: 'Opdater @.lower:common.common.officespaceListing.term',
    },

    officespaceListingsClose: {
      labels: {
      },
    },
    rentalListingsClose: {
      labels: {
        yearlyRent: 'Årlig leje {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
        compensation: 'Afståelsespris {\'(\'}@.lower:common.common.kr{\')\'}',
      },
    },
    salesListingsClose: {
      labels: {
        salesPrice: '@:common.common.salesPrice {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
      },
    },
    investmentListingsClose: {
      labels: {
        salesPrice: '@:common.common.salesPrice {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyRentalIncome: '@:common.common.yearlyRentalIncome',
        returnPercent: '@:common.common.returnPercent i % (Udregnes automatisk)',
      },
    },

    usersShow: {
      title: 'Bruger',
      labels: {
        id: '@:common.common.id',
        authId: '@:common.common.authId',
        email: '@:common.common.email',
        account: '@:common.common.account.term',
      },
      messages: {
        userNotFOund: '@:common.common.user.term "{userid}" eksisterer ikke!',
      },
    },
  },

  // Components in components folder. Specific for the components
  component: {
    accountsList: {
      name: '@:common.common.name',
      id: '@:common.common.id',
      role: '@:common.common.role',
    },
    accountSelect: {
      selectAccountLabel: 'Vælg en @.lower:common.common.account.term',
    },
    accountUsers: {
      userNotCreatedInAuth: 'Bruger har ikke oprettet sig endnu',
      removeRelation: 'Er du sikker på du vil fjerne relation?',
      noUsers: 'Der er en ingen brugere tilknyttet denne konto',
      table: {
        email: '@:common.common.email',
        role: '@:common.common.role',
        remove: '@:global.form.remove',
        thisIsYou: 'Dette er dig, skal det være muligt at slette dig selv?',
      },
    },
    accountUserForm: {
      toManyUsers: 'Der er mere end 1 bruger med email addresse "{email}", vælg den korrekte email herunder og klik tilføj',
      userAlreadyAdded: '@:common.common.user.term er allerede tilføjet',
      labels: {
        user: 'Vælg @.lower:common.common.user.term',
        email: 'Indtast @.lower:common.common.email',
        accountRole: 'Vælg @.lower:common.common.role',
      },
    },

    listingShow: {
      title: {
        rental: 'Leje annonce',
        investment: 'Investerings annonce',
        officespace: 'Kontor annonce',
        sales: 'Salgs annonce',
      },
      labels: {
        title: '@:common.common.title',
        flashline: '@:common.common.flashline',
        description: '@:common.common.description',
        terms: '@:common.common.terms',
        address: '@:common.common.address.term',
        advertisedAddress: '@:common.common.advertisedAddress',
        listingtypeSubcategory: '@:common.common.listingtypeSubcategory',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        energyLabel: '@:common.common.energyLabel',
        floorArea: '@:common.common.floorArea',
        secondaryArea: '@:common.common.secondaryArea',
        groundArea: '@:common.common.groundArea',
        usageProposal: '@:common.common.usageProposal',
        secondaryUsageProposals: '@:common.common.secondaryUsageProposals',
        facility: '@:common.common.facility',
        contactPhone: 'Kontakt telefonnummer',
        contactEmail: 'Kontakt email',
        externalListingURL: '@:common.common.externalListingURL',
        constructionYear: '@:common.common.constructionYear',
        yearlyOperatingCostDKK: '@:common.common.yearlyOperatingCostDKK',
        valueAddedTax: 'Tilføjet skatteværdi',
      },
    },
    listingsClose: {
      title: {
        rental: 'Afmeld annonce',
        investment: 'Afmeld annonce',
        officespace: 'Afmeld annonce',
        sales: 'Afmeld annonce',
      },
      type: {
        rental: 'Leje',
        investment: 'Investering',
        officespace: 'Kontorhotel',
        sales: 'Salg',
      },
      labels: {
        address: '@:common.common.address.term',
        advertisedAddress: '@:common.common.advertisedAddress',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        title: '@:common.common.title',
        flashline: '@:common.common.flashline',
        floorArea: '@:common.common.floorArea {\'(\'}@.lower:common.common.m2{\')\'}',
        secondaryArea: '@:common.common.secondaryArea {\'(\'}@.lower:common.common.m2{\')\'}',
        groundArea: '@:common.common.groundArea {\'(\'}@.lower:common.common.m2{\')\'}',
        listingtypeSubcategory: '@:common.common.listingtypeSubcategory',
        closedSince: 'Lukket siden',
        closingReason: 'Lukke årsager',
        closingReasonText: 'Beskrivelse af lukke årsager',
      },
    },
    listingsListTable: {
      visitCount: 'Antal besøg',
      leadsTotal: 'Leads total',
      conversation: 'Konverterings-rate',

      listingTypes: {
        0: 'TODO: Hvad er type 0?',
        1: 'Leje',
        2: 'Investering',
        3: 'Salg',
        4: 'Kontor',
      },

      options: {
        edit: 'Rediger emne',
        copy: 'Kopier emne',
        delete: 'Slet emne',
        close: 'Luk emne',
        statistics: 'Se statistik for emne',
        viewMainPage: 'Se emne på ejendomstorvet.dk',
        highlight: 'Fremhæv emne',
      },

      noListings: 'Der er ingen @.lower:common.common.listing.term',
    },

    dawaAutocomplete: {
      labels: {
        address: '@:common.common.address.term',
        useAdvertisedAddress: 'Benyt en @.lower:common.common.advertisedAddress',
        advertisedAddress: '@:common.common.advertisedAddress',
      },
      messages: {
        dawaError: 'Noget gik galt',
      },
    },
    userChangePasswordButton: {
      button: 'Reset kodeord',
      buttonIsSend: 'Email sendt',
      confirm: 'Vil du sende "request password change" til {email}',
      isSend: 'Email er sendt til {email}',
    },

    userChangePasswordForm: {
      submitButton: 'Nulstil kodeord',
      message: 'Efter du har klikket på knappen herover, vil du blive logget ud og bedt om at lave et nyt password',
    },

    searchAgentCard: {
      deleteConfirm: 'Er du sikker på du vil slette denne agent?',
      editLink: 'Se og rediger agenten',
      delete: 'Slet',
    },
  },
  layout: {
    theNavigationBar: {
      titles: {
        1: 'Ejendomstorvet medlem',
        2: 'Ejendomstorvet light annoncør',
        3: 'Ejendomstorvet premium annoncør',
        4: 'Ejendomstorvet statistik',
        5: 'Ejendomstorvet kommmune',
        user: 'Ejendomstorvet bruger',
      },
      listings: '@:common.common.listing.term',
      statistics: 'Statistik',
      account: '@:common.common.account.term',
      accountStatistics: '@:common.common.account.statistics',
      favorites: 'Favoritter',
      searchAgents: 'Søgeagenter',
      login: 'Log ind',
      logout: 'Log ud',
      gotoEt: 'Gå til ejendomstorvet.dk',
      newListing: 'Nyt @.lower:common.common.listing.term',
      loggedOnAs: 'Logget på som {email}',
      loggedOn: 'Logget på',
    },
  },
};
